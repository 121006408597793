import Footer from "../components/Footer";

function Contact() {
  return (
    <div>
      <h1>Contact</h1>
      <Footer />
    </div>
  );
}

export default Contact;
